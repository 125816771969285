import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Vend from 'assets/images/Vend.svg';

function ErrorFallback() {
  const [isCookieEnabled, setIsCookieEnabled] = useState(null);
  useEffect(() => {
    setIsCookieEnabled(navigator.cookieEnabled);
  }, []);

  return (
    <Box p={4}>
      <img src={Vend} alt="VendPark" height="48px" />
      <Typography variant="h5" mt={8}>
        {isCookieEnabled ? 'An Error Occurred' : 'Cookies are blocked'}
      </Typography>
      <Typography my={4}>
        {isCookieEnabled
          ? 'Our team have been notified. Please refresh and if the issue persits, contact support.'
          : 'The Vend web application requires cookies to be enabled to function properly. Please enable cookies in your browser settings.'}
      </Typography>

      <Button
        variant="outlined"
        color="inherit"
        onClick={() => window.location.reload()}
      >
        Reload the page
      </Button>
    </Box>
  );
}

export default ErrorFallback;
